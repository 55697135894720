import React from "react";

import "../../style/services.css";

const serviceData = [
  {
    icon: "ri-code-s-slash-fill",
    title: "Développement Mobile & Web",
    desc: "Nous vous accompagnons pour définir selon votre cahier des charges la meilleure technologie. ",
  },
  /*  {
    icon: "ri-code-s-slash-fill",
    title: "Développement web",
    desc: "Notre expérience avec les petits écrans est précieuse dans un monde où la majorité du trafic web provient d’appareils mobiles.",
  }, */
  /* {
    icon: "ri-cloud-line",
    title: "Hébergement",
    desc: "Nous  conseillons sur l’hébergement qui correspond aux spécificités de votre projet : performances, taux de disponibilité etc.",
  }, */
  {
    icon: "ri-pen-nib-line",
    title: "Ergonomie & design",
    desc: "Nous vous proposons des solutions personnalisées pour améliorer votre visibilité sur les moteurs de recherche et les reseaux sociaux.",
  },
  {
    icon: "ri-chat-smile-2-line",
    title: "Social Média",
    desc: "Nos Community Managers vous aident à établir et à maintenir une notoriété sur Facebook, Instagram, Twitter, etc.",
  },
  {
    icon: "ri-artboard-fill",
    title: "Formation",
    desc: "Nous accompagnons les entreprises et les particuliers à se former sur les meilleures technologies du moment.",
  },
];

const Services = () => {
  return (
    <section id="service">
      <div className="container">
        <div className="services__top-content">
          <h6 className="subtitle">Nos Services</h6>
          <h2>Gagnez du temps dans la gestion de votre Business avec</h2>
          <h2 className="highlight"> nos meilleurs services</h2>
        </div>

        <div className="service__item-wrapper">
          {serviceData.map((item, index) => (
            <div className="services__item">
              <span className="service__icon">
                <i class={item.icon}></i>
              </span>
              <h3 className="service__title">{item.title}</h3>
              <p className="description">{item.desc}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
