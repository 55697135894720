import React from "react";
import "../../style/testimonial.css";
import Slider from "react-slick";

import ava01 from "../../images/ava-1.png";
import ava02 from "../../images/ava-2.png";
import ava03 from "../../images/ava-3.png";

const testimonial = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
  };
  return (
    <section>
      <div className="container">
        <div className="slider__content-top">
          <h6 className="subtitle">Témoignages</h6>
          <h2>
            Approuvés par plus de
            <span className="highlight"> plusieurs stuctures. </span>
          </h2>
        </div>
        <div className="slider__wrapper">
          <Slider {...settings}>
            <div className="slider__item">
              <p className="description">
                Entreprise de qualité qui nous a aidé à mettre sur pied notre
                plateforme de vulgarisation du service d'assurance en
                Tunisie.C’est un vrai plaisir de travailler avec l’équipe d’IDA
                VISION. Toujours à l’écoute, disponible, rapide dans
                l’exécution. Les projets deviennent vite une réalité. Une agence
                de communication que je conseille sans aucune crainte. La
                réussite, je la souhaite à toute l’équipe.
              </p>

              <div className="customer__details">
                <div className="customer__img">
                  <img src={ava01} alt="customer" />
                </div>
                <div>
                  <h5 className="customer__name">Imen Gargouri</h5>
                  <p className="description"> CEO Assurance Gargouri</p>
                </div>
              </div>
            </div>

            <div className="slider__item">
              <p className="description">
                Je tiens encore à te remercier pour la restitution de ton
                travail ce matin. Nous sommes tous très séduits par votre
                proposition d’ergonomie et de design. Tu fais encore plus partie
                maintenant de l’aventure Max Scheller !
              </p>

              <div className="customer__details">
                <div className="customer__img">
                  <img src={ava02} alt="customer" />
                </div>
                <div>
                  <h5 className="customer__name">Nadhem Ben Jemma</h5>
                  <p className="description"> promoteur AMTA ACADEMY</p>
                </div>
              </div>
            </div>
            <div className="slider__item">
              <p className="description">
                Encore et encore merci pour le superbe travail que vous avez
                effectué sur le Book Matériel versions anglaise et française !
                Le dossier était costaud et les délais courts mais vous avez su
                tenir jusqu’au bout. Bravo à vous deux et à toutes les autres
                personnes de l’équipe mobilisées !
              </p>

              <div className="customer__details">
                <div className="customer__img">
                  <img src={ava03} alt="customer" />
                </div>
                <div>
                  <h5 className="customer__name">Hamdi Ghanai</h5>
                  <p className="description"> CEO Roi du Vapeur</p>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default testimonial;
