import React,{useState,useEffect} from "react"

import './App.css';

import Header from './components/Header/Header';
import Apropos from './components/UI/Apropos';
import Blog from './components/UI/Blog';
import Counter from './components/UI/Counter';
import Hero from './components/UI/Hero';
import Newsletter from './components/UI/Newsletter';
import Services from './components/UI/Services';
import Team from './components/UI/Team';
import Testimonial from './components/UI/Testimonial';
import Footer from './components/Footer/Footer';

function App() {

  const [theme, setTheme] = useState('')

  const toggleTheme = ()=>{
    theme === ''? setTheme('light-theme') :setTheme('')
  }

useEffect(()=>{
document.body.className=theme
},[theme])

  return (
    <>
      <Header theme={theme} toggleTheme={toggleTheme} />
      <Hero theme={theme}/>
      <Counter/>
      <Services/>
      <Apropos/>
      <Team/>
      <Blog/>
      <Testimonial />
      <Newsletter />
      <Footer />
    </>
  );
}

export default App;
