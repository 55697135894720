import React from "react";
import "../../style/hero.css";
import heroDarkImg from "../../images/hero-img2.png";
import lightImg from "../../images/light-hero-bg.jpg";

const Hero = ({ theme }) => {
  return (
    <section className="hero__section" id="accueil">
      <div className="container">
        <div className="hero__wrapper">
          <div className="hero__content">
            <div>
              <h2>En route vers la Perfection, voici</h2>
              <h2>Les meilleures solutions pour</h2>
              <h2 className="highlight">Amméliorer votre Marque</h2>
            </div>
            <p className="description">
              IDA Vision réalise des applications mobiles et sites web sur
              mesure, pour startups, PME et grandes entreprises. <br />
              Egalement le suivi de stratégie digitale, la création de contenus
              de marque et la visibilité digitale.
            </p>
            <div className="hero__btns">
              <button className="primary__btn">
                <a href="#contact">Nous Contacter</a>
              </button>
              <button className="secondary__btn">
                <a href="#about">Découvrir Plus</a>
              </button>
            </div>
          </div>
          <div className="hero__img">
            <img
              src={theme === "light-theme" ? lightImg : heroDarkImg}
              alt="hero-img"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
