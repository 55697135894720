import React from "react";
import "../../style/team.css";

import team11 from "../../images/team-21.png";
import team12 from "../../images/team-22.png";
import team13 from "../../images/team-23.png";
import team14 from "../../images/team-14s.png";

const teamMembers = [
 /*  {
    img: team11,
    name: "Oyono Loic",
    position: "Fondateur",
    description: "Ingénieur Big Data || Ingénieur Réseau Informatique  ",
  }, */
  {
    img: team12,
    name: "Dorra Ellouze",
    position: "Chef de projet ergonomie ",
    description: " Master en Marketing Digital",
  },
  {
    img: team13,
    name: "Ferjani Imen",
    position: "chef de projet Dev",
    description: " Ingénieur Informatique ",
  },
  {
    img: team14,
    name: "Pourquoi pas vous ?",
    position: "Rejoingnez nous..",
    description: "envoyez nous votre CV à nous@idavision.tech",
  },
];

const Team = () => {
  return (
    <section id="team" className="our__team">
      <div className="container">
        <div className="team__content">
          <h6 className="subtitle">Notre Equipe</h6>
          <h2>
            Faites la rencontre de nos{" "}
            <span className="highlight">chefs d'équipe</span>
          </h2>
        </div>

        <div className="team__wrapper">
          {teamMembers.map((item, index) => (
            <div className="team__item" key={index}>
              <div className="team__img">
                <img src={item.img} alt="team-img" />
              </div>
              <div className="team__details">
                <h4>{item.name}</h4>
                <p className="description">
                  {item.position} <br />
                  <span className="desc2">{item.description}</span>
                </p>
                <div className="team__member-social">
                  <span>
                    <a href="https://www.linkedin.com/company/ida-vision/">
                      <i class="ri-linkedin-line"></i>
                    </a>
                  </span>
                  <span>
                    <a href="https://www.facebook.com/idavisionweb/">
                    <i class="ri-facebook-line"></i>
                    </a>
                  </span>
                  <span>
                    <a href="https://www.instagram.com/idavision/">
                    <i class="ri-instagram-line"></i>
                    </a>
                  </span>
                  <span>
                    <a href="https://wa.me/+21646509016">
                    <i class="ri-whatsapp-line"></i>
                    </a>
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Team;
