import React from "react";
import "../../style/blog.css";

import videoImg from "../../images/video.png";
import articleImg from "../../images/article.png";
import caseStudy from "../../images/case-study.png";

const blogData = [
  {
    imgUrl: videoImg,
    title: "Video",
    desc: "Pour en savoir plus sur notre travail regardez ces vidéos ....",
    linkUrl: "https://www.youtube.com/watch?v=nmSUl-nQZBI",
  },
  {
    imgUrl: articleImg,
    title: "Article",
    desc: "Voulez-vous améliorer la façon dont votre marque interagit avec le client ?  Parlons en...",
    linkUrl: "https://www.youtube.com/watch?v=nmSUl-nQZBI",
  },
  {
    imgUrl: caseStudy,
    title: "Etude de cas",
    desc: "Pourquoi faire la refonte de son Site Web ?  On vous dit tout...",
    linkUrl: "https://www.youtube.com/watch?v=nmSUl-nQZBI",
  },
];

const Blog = () => {
  return (
    <section id="blog" className="blog">
      <div className="container">
        <div className="blog__top-content">
          <h6 className="subtitle">Notre Blog</h6>
          <h2>
            Jetez un coup d'oeil sur nos
            <span className="highlight"> récentes actualités</span>
          </h2>
        </div>
        <div className="blog__wrapper">
          {blogData.map((item, index) => (
            <div className="blog__item" key={index}>
              <h3 className="title_blog">{item.title}</h3>
              <div className="blog__img">
                <img src={item.imgUrl} alt="" />
              </div>
              <p className="description blog__desc">{item.desc}</p>
              <div>
                <a href={item.linkUrl} className="learn__more">
                  <i class="ri-arrow-right-line"></i>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Blog;
