import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import "../../style/newsletter.css";

const Newsletter = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_mha8e3q",
        "template_826kg0i",
        form.current,
        "V3UkWc-OuoOpz3SHV"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  return (
    <section id="contact" className="newsletter">
      <div className="container">
        <div className="newsletter__wrapper">
          <div className="newsletter__content">
            <h6 className="subtitle">A vous de jouer</h6>
            <h2>
              Vous êtes pressés,
              <a href="tel:+21646509016">
                <span className="highlight">
                  ' cliquez ici <br />
                </span>
              </a>
              pour nous appeler au <br />
              <span className="highlight"> +216 46 50 90 16</span>.
              <br />
              Par whatsapp{" "}
              <span className="highlight">
                {" "}
                <a href="https://wa.me/+21646509016">
                  <span className="highlight">
                    ici. <br />
                  </span>
                </a>{" "}
              </span>{" "}
              <br />
              Ou alors laissez votre adresse et nous explorerons ensemble toutes
              <span className="highlight">
                <br /> vos idées cachées
              </span>
              !
            </h2>
          </div>
          <form className="newsletter__form" ref={form} onSubmit={sendEmail}>
            <label></label>
            <input type="text" placeholder="votre nom" name="user_name" />
            <label></label>
            <input type="email" placeholder="votre email" name="user_email" />
            <label></label>
            <textarea name="message" placeholder="votre message" />
            <input
              type="submit"
              value="Envoyer"
              className="secondary__btn subscribe__btn"
            />
            {/* <div className="newsletter__form">
            <input type="text" name="user_name" placeholder="votre nom" />
           
          </div>
          <div className="newsletter__form">
            <input type="email" name="user_email" placeholder="votre email" />
            <button onSubmit={sendEmail} className="secondary__btn subscribe__btn">Envoyer</button>
          </div> */}
          </form>
        </div>
      </div>
    </section>
  );
};

export default Newsletter;
